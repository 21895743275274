table {
    @apply w-full border-separate border-spacing-0;
    @apply bg-white border border-gray-300;

    thead {   
        @apply text-lg;
           
        tr {
            @apply bg-primary text-white;
        }
    }

    tr {
        &:nth-child(even) {
            @apply bg-gray-100;
        }

        th, td {
            @apply px-4 py-1 text-center text-nowrap;
        }

        th {
            @apply !border-b;
        }

        &:last-child {
            th, td {
                @apply border-0;
            }
        }
    }
}

table thead tr th.has-order {
    @apply cursor-pointer hover:bg-primary-dark;
}